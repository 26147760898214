<template>
  <!-- 主内容 -->
  <div class="main-content__wrapper">
    <div class="user-info__container">
      <UserInfo
        :is-prime="isShowPrimeIcon"
        :is-show-saver="isShowSaverIcon"
      />
      <UserAssets
        :is-prime="!!primeInfo"
        :is-show-saver="!!saverInfo"
        :expire-point-coupon-info="expirePointCouponInfo"
        :personal-center-entrance-info="personalCenterEntranceInfo"
      />
    </div>
    <MyOrder :dynamic-service="dynamicService" />
    <RecommendCcc v-if="isShowRecommend" />
  </div>
</template>
<script setup>
import { computed, defineAsyncComponent } from 'vue'
import UserInfo from './UserInfo.vue'
import UserAssets from './UserAssets.vue'
import MyOrder from './MyOrder.vue'
import { useCommonState } from '../../utils/useCommonState'

const RecommendCcc = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "RecommendCcc" */ '../../components/RecommendCcc.vue'
  )
)
const props = defineProps({
  expirePointCouponInfo: {
    type: Object,
    default: () => ({})
  },
  personalCenterEntranceInfo: {
    type: Object,
    default: () => ({})
  },
  dynamicService: {
    type: Object,
    default: () => ({})
  }
})

const { abtInfo } = useCommonState()
// 付费用户  1-付费会员，2-超省卡
const primeInfo = computed(() =>
  props.personalCenterEntranceInfo.member_card_list?.find(
    item => item.cardType == 1
  )
)
const isShowPrimeIcon = computed(() => primeInfo.value?.isPaid === 1)
// 超省卡 icon 是否展示
const saverInfo = computed(() =>
  props.personalCenterEntranceInfo.member_card_list?.find(
    item => item.cardType == 2
  )
)
const isShowSaverIcon = computed(() => saverInfo.value?.isPaid === 1)
const isShowRecommend = computed(
  () => abtInfo.value.pcmeRecommend?.param?.pcmeRecommend == 'on'
)
</script>
<style lang="less">
.main-content__wrapper {
  flex: 1;
  // min-width: 600px;
  width: calc(100% - 286px);
  margin-right: 10px;
  & when (@IS_RW) {
    border-radius: 4px;
  }
}

.user-info__container {
  padding: 20px;
  background-color: #fff;
}
</style>
