export const getMainTextMaxWidth = (isSingleCard, benefitItems) => {
  let maxWidth = 0
  if (isSingleCard) {
    switch (benefitItems.length) {
      case 1:
        maxWidth = 350
        break
      case 2:
        maxWidth = 230
        break
      case 3:
        maxWidth = 140
        break
      default:
        break
    }
  } else {
    switch (benefitItems.length) {
      case 1:
        maxWidth = 240
        break
      case 2:
        maxWidth = 113
        break
      case 3:
        maxWidth = 70
        break
      default:
        break
    }
  }

  return maxWidth
}
