<template>
  <UserCenterContainer
    v-if="context.useBffApi"
    :context="context"
  />
  <!-- <App
    v-else
    :context="context"
  /> -->
</template>

<script>
import { defineComponent } from 'vue'
import UserCenterContainer from './index-new.vue'
import { transfromAndCutImg } from 'public/src/services/resource/index'

export default defineComponent({
  name: 'UserCenter',
  components: {
    UserCenterContainer,
    // App: defineAsyncComponent(() => import('./index.vue')),
  },
  provide() {
    return {
      cutImg: this.cutImg,
    }
  },
  props: {
    context: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    cutImg(imgUrl, designWidth) {
      const { RESOURCE_SDK } = this.context ?? {}
      const { deviceData = '', isSupportWeb = '', isSupprotCut = false } = RESOURCE_SDK || {}

      const cutData = {
        deviceData,
        isSupportWebp: Boolean(isSupportWeb),
        isSupprotCut,
        imgUrl,
        designWidth: Number(designWidth),
        exp: {},
      }

      return transfromAndCutImg(cutData)
    }
  }
})
</script>
