<template>
  <div 
    class="saver-card"
    :style="{
      '--semicircle-color': '#FFF8FD',
      '--card-button-text-color': '#F82854',
      '--card-button-background': '#FEE9EE',
      '--sub-text-font-size': isSingleCard ? '12px' : '11px',
      '--main-text-max-width': `${getMainTextMaxWidth(isSingleCard, saverInfo.couponInfoList?.slice(0,3) || [])}px`,
    }"
    @click="toSaverLandingPage"
  >
    <div class="saver-card__header">
      <img 
        class="saver-card__header_logo" 
        :src="cutImg(saverInfo.cardIcon)"
        alt="SHEIN SAVER" 
      />
    </div>

    <p 
      class="saver-card__desc" 
      v-html="saverInfo.subText ? saverInfo.subText : '&nbsp;'"
    ></p>

    <div class="card-benefits">
      <template 
        v-for="(benefitItem, index) in saverInfo.couponInfoList?.slice(0,3) || []"
        :key="`${benefitItem.type}-${index}`"
      >
        <div
          :style="{
            '--semicircle-border-color': benefitItem.couponType === '1' ? 'rgba(14, 140, 51, 0.10)' : 'rgba(255, 92, 45, 0.10)',
          }"
          :class="['card-benefits__benefit-item', benefitItem.couponType === '1' ? 'shipping-benefit-background' : 'normal-benefit-background']"
        >
          <p
            :class="[benefitItem.thresholdTip && 'card-benefits__benefit-item_main-text']"
            style="line-height: 15px;"
            v-html="benefitItem.discountTip"
          ></p>
          <p 
            v-if="benefitItem.thresholdTip"
            class="saver-card__discount-threshold"
            :style="isSingleCard ? { fontSize: '12px', lineHeight: '12px' } : { lineHeight: '12px' }"
            v-html="benefitItem.thresholdTip"
          ></p>

          <div 
            v-if="!benefitItem.expireAt && benefitItem.cornerText"
            class="card-benefits__benefit-item_corner-text"
          >
            <span>{{ benefitItem.cornerText }}</span>
          </div>
          <BenefitCountDown
            v-if="benefitItem.expireAt"
            :end-time="`${benefitItem.expireAt * 1000}`"
            class="card-benefits__benefit-item_corner"
          />
        </div>
      </template>
    </div>

    <div class="card-button">
      <p v-html="saverInfo.buttonTip"></p>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, watch, defineAsyncComponent } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getMainTextMaxWidth } from './utils.js'

const BenefitCountDown = defineAsyncComponent(() => import(/* webpackChunkName: "benefit-countDown" */'./BenefitCountDown.vue'))

const commonState = inject('commonState')
const { langPath = '' } = commonState.constantData || {}

const props = defineProps({
  saverInfo: {
    type: Object,
    default: () => ({})
  },
  isSingleCard: {
    type: Boolean,
    default: false
  }
})

const buriedObject = computed(() => props.saverInfo.buriedObject || [])

const cutImg = inject('cutImg')

watch(() => buriedObject.value, (val) => {
  if (!val?.length) return
  const saverReach = val.find(n => n.buriedKey === 'saver_reach')?.defaultParams
  daEventCenter.triggerNotice({
    daId: '1-22-2-1000',
    extraData: saverReach
  })
  const saverExpose = val.find(n => n.buriedKey === 'saver' && n.buriedType === 1)?.defaultParams
  daEventCenter.triggerNotice({
    daId: '1-22-2-1001',
    extraData: {
      activity_from: 'me',
      ...saverExpose
    }
  })
}, { immediate: true })

const toSaverLandingPage = () => {
  const saverClick = buriedObject.value.find(n => n.buriedKey === 'saver' && n.buriedType === 2)?.defaultParams
  daEventCenter.triggerNotice({
    daId: '1-22-2-1002',
    extraData: {
      activity_from: 'me',
      ...saverClick
    }
  })
  if (props.saverInfo?.appRoute) {
    location.href = langPath + (props.saverInfo.appRoute[0] === '/' ? props.saverInfo.appRoute : `/${props.saverInfo.appRoute}`)
  } else {
    location.href = langPath + (props.saverInfo?.url?.[0] === '/' ? props.saverInfo?.url : `/${props.saverInfo?.url}`)
  }
  
}

</script>

<style lang="less">
@import "./style/Pannel.less";
.saver-card {
  flex: 1;
  border-radius: 6px;
  border: 0.5px solid rgba(248, 37, 100, 0.10);
  background: #FFF8FD;
  padding: 7px 12px 9px;
  cursor: pointer;

  &__header {
    margin-bottom: 9px;
    height: 22px;
    &_logo {
      width: 98px;
      height: 22px;
    }
  }

  &__desc {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
    .line-camp(1);
  }

  &__discount-threshold {
    color: #000;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    margin-top: 2px;
    .line-camp(2);
    word-break: break-word;
  }

}
</style>
