<template>
  <!-- 会员模块 -->
  <div 
    :class="isNewStyle ? 'vip-modules__wrapper-new': 'vip-modules__wrapper'"
    :style="isNewStyle ? 'gap: 11px;' : ''"
  >
    <PrimePannelNew
      v-if="isNewStyle && isPrime"
      :is-show-saver="isShowSaver"
      :is-single-card="isSingleCard"
      :prime-info="primeInfo"
      :payback-info="data.payback_info || {}"
      :renew-pop-up-info="data.renew_pop_up_info || {}"
      :renew-pop-up-info-v2="data.renew_pop_up_info_v2 || {}"
      :free-gift-popup-info="data.freeGiftPopupInfo || {}"
      @dialog-opened="openedCallback"
    />
    <ExtraPannelNew
      v-if="isNewStyle && isShowSaver"
      :is-single-card="isSingleCard"
      :saver-info="saveInfo"
    />
    <!-- 付费会员 -->
    <prime-pannel
      v-if="!isNewStyle && isPrime"
      :is-show-saver="isShowSaver"
      :language="language"
      :prime-info="primeInfo"
      :payback-info="data.payback_info || {}"
      :renew-pop-up-info="data.renew_pop_up_info || {}"
      :renew-pop-up-info-v2="data.renew_pop_up_info_v2 || {}"
      :free-gift-popup-info="data.freeGiftPopupInfo || {}"
      :apollo-config="apolloConfig"
      @dialog-opened="openedCallback"
    />
    <!-- 超省卡 -->
    <extra-pannel
      v-if="!isNewStyle && isShowSaver"
      :is-show-prime="isPrime"
      :language="language"
      :save-info="saveInfo"
    />
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import PrimePannel from './PrimePannel.vue'
import ExtraPannel from './ExtraPannel.vue'
import PrimePannelNew from './PrimePannelNew.vue'
import ExtraPannelNew from './ExtraPannelNew.vue'
import { isShowRenewPopUpDialog, isShowRenewPopUpDialogV2, isShowFreeGiftDialog, isShowPayBackDialog, reportUserCenterDialogSuccess } from './dialogHooks'


const props = defineProps({
  apolloConfig: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  isShowSaver: {
    type: Boolean,
    default: false
  },
  isPrime: {
    type: Boolean,
    default: false
  },
  data: {
    type: Object,
    default: () => ({})
  }
})

const popDialogType = ref(null)

let hasReportDialog = false

// 付费会员数据
const primeInfo = computed(
  () => props.data.member_card_list?.find(item => item.cardType === 1) || {}
)
// 超省卡数据
const saveInfo = computed(
  () => props.data.member_card_list?.find(item => item.cardType === 2) || {}
)

// 会员卡/超省卡新样式
const isNewStyle = computed(() => props.data.member_card_list?.find(item => item.cardStyle === 'v3'))
const isSingleCard = computed(() => props.data.member_card_list?.length === 1)

const DialogTypeEnum = {
  NEW_RENEW_DIALOG: 'NEW_RENEW_DIALOG',
  RENEW_DIALOG: 'RENEW_DIALOG',
  PAYBACK_DIALOG: 'PAYBACK_DIALOG',
  FREE_GIFT_DIALOG: 'FREE_GIFT_DIALOG',
  NO_DIALOG: 'NO_DIALOG'
}

const calcPopInfo = () => {
  if (!(props.isPrime && Object.keys(props.data).length)) return DialogTypeEnum.NO_DIALOG
  
  const { freeGiftPopupInfo, renew_pop_up_info, renew_pop_up_info_v2, payback_info } = props.data ?? {}
  if (isShowRenewPopUpDialogV2(renew_pop_up_info_v2)) {
    return DialogTypeEnum.NEW_RENEW_DIALOG
  }
  if (isShowRenewPopUpDialog(renew_pop_up_info)) {
    return DialogTypeEnum.RENEW_DIALOG
  }
  if (isShowFreeGiftDialog(freeGiftPopupInfo)) {
    return DialogTypeEnum.FREE_GIFT_DIALOG
  }
  if (isShowPayBackDialog(payback_info)) {
    return DialogTypeEnum.PAYBACK_DIALOG
  }
  return DialogTypeEnum.NO_DIALOG
}

const openedCallback = dialogType => {
  hasReportDialog = true
  reportUserCenterDialogSuccess({
    metricName: dialogType,
    tags: {
      status: dialogType === popDialogType.value ? '1' : '0'
    }
  })
}

onMounted(() => {
  popDialogType.value = calcPopInfo()
  if (popDialogType.value === DialogTypeEnum.NO_DIALOG) return
  setTimeout(() => {
    if (!hasReportDialog) {
      reportUserCenterDialogSuccess({
        metricName: popDialogType.value,
        tags: {
          status: '0'
        }
      })
    }
  }, 10000)
})
</script>

<style lang="less" scoped>
.vip-modules__wrapper,.vip-modules__wrapper-new {
  display: flex;
  margin-top: 8px;
}
</style>
