<template>
  <div 
    class="club-card"
    :style="{
      '--semicircle-color': '#FFFCFA',
      '--card-button-text-color': '#873C00',
      '--card-button-background': '#FAF0EC',
      '--sub-text-font-size': isSingleCard ? '12px' : '11px',
      '--main-text-max-width': `${getMainTextMaxWidth(isSingleCard, primeInfo.benefit?.slice(0,3) || [])}px`,
      '--sub-text-max-width': isSingleCard ? '545px' : '260px',
      '--tag-text-color': isExpired ? '#767676': '#FA6338',
      '--tag-background-color': isExpired ? '#F6F6F6' : '#FEEFEB',
      '--tag-border-color': isExpired ? 'rgba(118, 118, 118, 0.20)' : 'rgba(250, 99, 56, 0.20)',
    }"
    @click.stop="toPrimeLandingPage"
  >
    <div class="club-card__header">
      <img 
        class="club-card__header_logo" 
        :src="cutImg(primeInfo.cardIcon)"
        alt="SHEIN CLUB" 
      />
      <!-- 省额文案 -->
      <p 
        v-if="primeInfo.mainText"
        class="club-card__tag"
        v-html="primeInfo.mainText"
      ></p>
      <!-- 如果没有省额文案且会员已过期,则展示过期 Tag -->
      <div
        v-if="!primeInfo.mainText && isExpired"
        class="club-card__tag"
        style="font-size: 12px;"
      >
        {{ primeInfo.statusTip }}
      </div>
    </div>
    
    <p
      class="club-card__desc"
      v-html="primeInfo.subText ? primeInfo.subText : '&nbsp;'"
    ></p>

    <!-- 有且只有免费礼商品一项权益 -->
    <template v-if="primeInfo.benefit?.[0]?.type === 'freeGiftGoods'">
      <div class="club-card__free-gift-container">
        <div 
          v-for="(giftIcon, i) in primeInfo.benefit?.[0]?.icons"
          :key="i"
          class="club-card__free-gift"
        >
          <div
            class="club-card__free-gift_item"
          >
            <img 
              :src="cutImg(giftIcon)"
              alt="FreeGift" 
            />
            <p
              class="club-card__free-gift_sub-text"
              :style="isSingleCard ? { fontSize: '12px' } : {}"
              v-html="primeInfo.benefit?.[0]?.subText"
            ></p>
          </div>
        </div>
      </div>
    </template>
    <div
      v-else
      class="card-benefits"
    >
      <template 
        v-for="(benefitItem, index) in primeInfo.benefit?.slice(0,3) || []"
        :key="`${benefitItem.type}-${index}`"
      >
        <div
          :style="{
            '--semicircle-border-color': benefitItem.type === 'freeShipping' ? 'rgba(14, 140, 51, 0.10)' : 'rgba(255, 92, 45, 0.10)',
          }"
          :class="['card-benefits__benefit-item', benefitItem.type === 'freeShipping' ? 'shipping-benefit-background' : 'normal-benefit-background']"
        >
          <!-- 有权益 icon -->
          <template v-if="['freeGift', 'discountIcon', 'pointIcon'].includes(benefitItem.type) && benefitItem.icons?.length">
            <img
              :src="cutImg(benefitItem.icons?.[0])"
              style="width: 20px;height: 19px;margin-bottom: 3px;"
              :alt="benefitItem.type" 
            />
            <p
              class="card-benefits__benefit-item_sub-text"
              v-html="benefitItem.subText"
            ></p>
          </template>
            
          <template v-else>
            <div 
              class="card-benefits__benefit-item_container"
            >
              <div :style="benefitItem.middleText ? { maxWidth: '55%' } : {}">
                <p
                  class="card-benefits__benefit-item_main-text"
                  v-html="benefitItem.mainText"
                ></p>
                <p
                  class="card-benefits__benefit-item_sub-text"
                  style="font-size: 9px;"
                  v-html="benefitItem.subText"
                ></p>
              </div>
              <div 
                v-if="benefitItem.middleText"
                class="card-benefits__benefit-item_mid"
              >
                <p
                  class="card-benefits__benefit-item_mid-text"
                  v-html="benefitItem.middleText"
                ></p>
              </div>
            </div>
          </template>

          <div 
            v-if="!benefitItem.endTime && benefitItem.cornerText"
            class="card-benefits__benefit-item_corner-text"
          >
            <span>{{ benefitItem.cornerText }}</span>
          </div>
          <BenefitCountDown
            v-if="benefitItem.endTime"
            :end-time="`${benefitItem.endTime * 1000}`"
            :count-down-text="benefitItem.countdownText"
            class="card-benefits__benefit-item_corner"
          />
        </div>
      </template>
    </div>

    <div class="card-button">
      <p v-html="primeInfo.buttonTip"></p>
    </div>
  </div>

  <ClientOnly>
    <!-- 回本动效弹窗 -->
    <div
      style="position: absolute;"
      @click.stop=""
    >
      <PayBackDialog
        v-if="!isShowRenewPopUpDialogV2 && !isShowRenewPopUpDialog && !isShowFreeGiftDialog && isShowPayBackDialog"
        :is-show="isShowPayBackDialog"
        :payback-info="paybackInfo"
        @dialog-opened="openedCallback"
      />
    </div>

    <!-- 续费弹窗 -->
    <div
      style="position: absolute;"
      @click.stop=""
    >
      <RenewDialog
        v-if="!isShowRenewPopUpDialogV2 && isShowRenewPopUpDialog"
        :is-show="isShowRenewPopUpDialog && !isShowRenewPopUpDialogV2"
        :config-data="formatRenewPopUpInfo"
        @dialog-opened="openedCallback"
      />
      <RenewDialogV2
        v-if="isShowRenewPopUpDialogV2"
        :is-show="isShowRenewPopUpDialogV2"
        :config-data="formatRenewPopUpInfo"
        @dialog-opened="openedCallback"
      />
    </div>

    <!-- 免费礼弹窗 -->
    <div
      style="position: absolute;"
      @click.stop=""
    >
      <FreeGiftDialog
        v-if="!isShowRenewPopUpDialogV2 && !isShowRenewPopUpDialog && isShowFreeGiftDialog"
        :is-show="isShowFreeGiftDialog"
        :free-gift-popup-info="freeGiftPopupInfo"
        @dialog-opened="openedCallback"
      />
    </div>
  </ClientOnly>
</template>

<script setup>
import { inject, computed, defineAsyncComponent } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { getLocalStorage, setLocalStorage, removeLocalStorage, windowCacheDeleteCb } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { getMainTextMaxWidth } from './utils.js'

import PayBackDialog from './PayBackDialog.vue'
import RenewDialog from './RenewDialog.vue'
import RenewDialogV2 from './RenewDialogNew.vue'
import FreeGiftDialog from './FreeGiftDialog.vue'

const BenefitCountDown = defineAsyncComponent(() => import(/* webpackChunkName: "benefit-countDown" */'./BenefitCountDown.vue'))

daEventCenter.addSubscriber({ modulecode: '1-22-8' })

const CLUB_STATUS = {
  NEAR_EXPIRED: '1', // 临期
  EXPIRED: '2' // 过期
}
const commonState = inject('commonState')
const cutImg = inject('cutImg')
const { langPath = '', SiteUID = '' } = commonState.constantData || {}

const emits = defineEmits(['dialog-opened'])
const props = defineProps({
  primeInfo: {
    type: Object,
    default: () => ({})
  },
  isShowSaver: {
    type: Boolean,
    default: false
  },
  isSingleCard: {
    type: Boolean,
    default: false
  },
  paybackInfo: {
    type: Object,
    default: () => ({})
  },
  renewPopUpInfo: {
    type: Object,
    default: () => ({})
  },
  renewPopUpInfoV2: {
    type: Object,
    default: () => ({})
  },
  freeGiftPopupInfo: {
    type: Object,
    default: () => ({})
  },
})

const isExpired = computed(() => props.primeInfo.status === CLUB_STATUS.EXPIRED)

// 有数据就展示v2, 没有就展示老弹窗
const isShowRenewPopUpDialogV2 = computed(() => {
  if(!props.renewPopUpInfoV2 || !Object.keys(props.renewPopUpInfoV2)?.length) return false
  // 2. 判断是临期还是逾期，
  // 若差值≥0，则为临期状态，将差值换算为临期天数
  // 若临期天数为0~3天，用户每天首次访问个人中心，均弹出1次
  // 若临期天数为4~30天，用户每间隔5天 首次访问个人中心，弹出1次
  // 若差值<0，则为逾期状态，将差值换算为逾期天数
  // 若逾期天数为0~7天，用户每间隔2天 首次访问个人中心，弹出1次
  // 若逾期天数为8~21天，用户每间隔5天 首次访问个人中心，弹出1次
  // 若逾期天数为22~60天，用户每间隔10天 首次访问个人中心，弹出1次
  const currentTime = new Date().getTime()
  const time = props.renewPopUpInfoV2?.totalEndTime
  const intervalTime = props.renewPopUpInfoV2?.intervalTime
  const flag = timeCheck(
    intervalTime * 1000,
    currentTime,
    time >= 0 ? 'nearExpired' : 'expired'
  )
  return flag && props.renewPopUpInfoV2
})

const isShowRenewPopUpDialog = computed(() => {
  if(!props.renewPopUpInfo || !Object.keys(props.renewPopUpInfo)?.length) return false
  // 插入场景
  // 当以下条件同时满足时，不弹付费会员续费引导弹窗：
  // ① 无省额
  // /v2/User/prime/paidDetail接口的req_currency_price字段 < 0
  // ② 无会费价&会费券
  // /v2/User/prime/queryMemberBuyProduct接口的total_discount_price < 0
  // ③ 无买赠活动
  // /v2/User/prime/queryMemberBuyProduct接口的gift_type≠1
  const req_currency_price = props.renewPopUpInfo?.req_currency_price || 0
  const total_discount_price =
    props.renewPopUpInfo?.total_discount_price || 0
  const flag1 = req_currency_price <= 0
  const flag2 = total_discount_price <= 0
  const flag3 = props.renewPopUpInfo?.have_gift_flag

  if (flag1 && flag2 && flag3) {
    return false
  }

  // 2. 判断是临期还是逾期，
  // 若差值≥0，则为临期状态，将差值换算为临期天数
  // 若临期天数为0~3天，用户每天首次访问个人中心，均弹出1次
  // 若临期天数为4~30天，用户每间隔5天 首次访问个人中心，弹出1次
  // 若差值<0，则为逾期状态，将差值换算为逾期天数
  // 若逾期天数为0~7天，用户每间隔2天 首次访问个人中心，弹出1次
  // 若逾期天数为8~21天，用户每间隔5天 首次访问个人中心，弹出1次
  // 若逾期天数为22~60天，用户每间隔10天 首次访问个人中心，弹出1次
 
  const currentTime = new Date().getTime()
  const time = props.renewPopUpInfo?.total_end_time

  const flag = timeCheck(
    props.renewPopUpInfo?.interval_time * 1000,
    currentTime,
    time >= 0 ? 'nearExpired' : 'expired'
  )
  
  return !!flag
}) 

const isShowPayBackDialog = computed(() => {
  if(typeof window === 'undefined' || (isShowRenewPopUpDialog.value || isShowRenewPopUpDialogV2.value) || isShowFreeGiftDialog.value) return false

  const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'userCenter/PrimePannel' })
  const cacheKey = `pay_back_cache_${memberId || ''}_${SiteUID}`

  // payback_info为null 清除当前(站点+id)缓存
  if (!props.paybackInfo) {
    removeLocalStorage(cacheKey)
    return false
  }
  const cacheRoi = getLocalStorage(cacheKey)
  const newRoi = Number(props.paybackInfo.popups_info?.roi)
  if (cacheRoi) {
    if (Number(cacheRoi) !== newRoi) {
      setLocalStorage({ key: cacheKey, value: newRoi })
      return true
    }
    return false
  }
  setLocalStorage({ key: cacheKey, value: newRoi })
  return true
}) 

const isShowFreeGiftDialog = computed(() => {
  if(typeof window === 'undefined' || (isShowRenewPopUpDialog.value || isShowRenewPopUpDialogV2.value)) return false
  if(!props.freeGiftPopupInfo || !props.freeGiftPopupInfo.giftInfoList || !props.freeGiftPopupInfo?.giftInfoList?.length) {
    return false
  }
  // 清除sw缓存
  windowCacheDeleteCb({ keys: ['pcMeHtml'] })
  return true
}) 
     
const formatRenewPopUpInfo = computed(() => {
  if(props.renewPopUpInfoV2 && Object.keys(props.renewPopUpInfoV2)?.length) {
    return props.renewPopUpInfoV2
  }
  // 是否过期
  const isExpired = props.renewPopUpInfo.total_end_time < 0
  return {
    isExpired,
    isCoupons: props.renewPopUpInfo?.total_discount_price > 0,
    seconds: props.renewPopUpInfo.total_end_time,
    ...props.renewPopUpInfo
  }
})
      
const toPrimeLandingPage = () => {
  daEventCenter.triggerNotice({
    daId: '1-22-8-2',
    extraData: props.primeInfo?.buriedObject?.[0]?.defaultParams || {}
  })

  if (props.primeInfo?.appRoute) {
    location.href = langPath + (props.primeInfo.appRoute[0] === '/' ? props.primeInfo.appRoute : `/${props.primeInfo.appRoute}`)
  } else {
    location.href = langPath + (props.primeInfo?.url?.[0] === '/' ? props.primeInfo?.url : `/${props.primeInfo?.url}`)
  }
}

// 校验缓存时间是否失效
const timeCheck = (time, currentTime, type) => {
  const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'userCenter/PrimePannel' })
  const cacheKey =
    SiteUID + '-' + (memberId || '') + '-'
  // 临期key
  const nearExpiredKey = cacheKey + 'primeV2RenewDialog'
  // 逾期key
  const ExpiredKey = cacheKey + 'primeV2ExpiredRenewDialog'

  let cacheTimeKey
  if (type == 'nearExpired') {
    cacheTimeKey = nearExpiredKey
  } else {
    cacheTimeKey = ExpiredKey
  }
  const cacheTime = +getLocalStorage(cacheTimeKey)

  if (!cacheTime) {
    // 不存在缓存，触发弹窗
    return true
  } else {
    // 先判断缓存是否失效，如果未失效，不触发弹窗,返回false
    // 如果缓存失效,触发弹窗，存储当前时间，返回true

    // 当前时间 2024年10月12日 10：20:30
    // 0天失效，2024年10月13日 10:20:30
    // 24 小时/天 × 60 分钟/小时 × 60 秒/分钟 × 1000 毫秒/秒
    // 缓存的开始时间
    const cacheDayStartTime = new Date(cacheTime).getTime()
    // 当天的开始时间
    const currentDayStartTime = new Date(currentTime).getTime()
    // 命中缓存
    if (currentDayStartTime - cacheDayStartTime < time) {
      return false
    } else {
      return true
    }
  }
}

const openedCallback = (dialogType) => {
  emits('dialog-opened', dialogType)
}

</script>

<style lang="less">
@import "./style/Pannel.less";
.club-card {
  flex: 1;
  border-radius: 6px;
  border: 0.5px solid rgba(135, 60, 0, 0.10);
  background: #FFFCFA;
  padding: 7px 12px 9px;
  cursor: pointer;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    height: 22px;

    &_logo {
      width: 106px;
      height: 22px;
    }
  }

  &__tag {
    font-family: "SF UI Text";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--tag-text-color);
    font-size: 14px;
    margin-left: 4px;
    border: 0.5px solid var(--tag-border-color);
    background: var(--tag-background-color);
    padding: 3px 6px;
    .line-camp(1);
    word-break: break-all;
    max-width: calc(var(--sub-text-max-width) - 120px);
  }

  &__desc {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
    .line-camp(1);
    word-break: break-all;
    max-width: var(--sub-text-max-width);
  }

  &__free-gift-container {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    gap: 4px;
  }

  &__free-gift {
    display: flex;
    justify-content: center;
    gap: 3px;
    
    &_item {
      position: relative;

      img {
        width: 48px;
        height: 48px;
        border-radius: 2px;
      }
      p {
        position: absolute;
        bottom: 0;
        display: flex;
        width: 48px;
        height: 13px;
        padding: 1px 2px;
        color: #fff;
        justify-content: center;
        align-items: center;
        border-radius: 0px 0px 2px 2px;
        background: #873C00;
      }
    }

    &_sub-text {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      .line-camp(1);
    }

   
  }
}
</style>
